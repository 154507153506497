var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box", attrs: { id: "box1" } },
    [
      _c(
        "a-tooltip",
        { attrs: { placement: "top" } },
        [
          _vm.boxWidth - Number(_vm.breadcurmbEndWidth) <
          Number(_vm.breadcurmbWidth)
            ? _c(
                "template",
                { slot: "title" },
                _vm._l(_vm.breadcurmb, function (item, index) {
                  return _c(
                    "span",
                    {
                      key: item.id,
                      on: {
                        click: function ($event) {
                          return _vm.getBreadcurmb(item)
                        },
                      },
                    },
                    [
                      index !== 0 ? _c("span", [_vm._v(" > ")]) : _vm._e(),
                      _c("span", { staticClass: "tooltip" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "a-breadcrumb",
            { staticClass: "ellipsis", attrs: { separator: ">" } },
            _vm._l(_vm.breadcurmb, function (item) {
              return _c(
                "a-breadcrumb-item",
                { key: item.id, attrs: { href: "" } },
                [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.getBreadcurmb(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        2
      ),
      JSON.stringify(_vm.breadcurmbEnd) !== "{}"
        ? _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                {
                  staticClass: "end",
                  style: { width: _vm.breadcurmbEndWidth + "px" },
                  attrs: { href: "" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "current_item",
                      on: {
                        click: function ($event) {
                          return _vm.getBreadcurmb("end")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "endIcon" }, [_vm._v(" > ")]),
                      _vm._v(" " + _vm._s(_vm.breadcurmbEnd.title)),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }