var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.searchResultList && _vm.searchResultList.length > 0
      ? _c(
          "div",
          { staticClass: "check_box" },
          [
            _c(
              "a-checkbox-group",
              {
                model: {
                  value: _vm.list,
                  callback: function ($$v) {
                    _vm.list = $$v
                  },
                  expression: "list",
                },
              },
              _vm._l(_vm.searchResultList, function (item) {
                return _c("div", { key: item.id }, [
                  item.identification === "dep"
                    ? _c(
                        "div",
                        { staticClass: "margin_bottom" },
                        [
                          _c("a-checkbox", {
                            attrs: { value: item.id },
                            on: {
                              change: function ($event) {
                                $event.stopPropagation()
                                return _vm.onChange($event)
                              },
                            },
                          }),
                          _c("span", { staticClass: "cursor peo_box" }, [
                            _c("img", {
                              staticClass: "icon",
                              attrs: { src: _vm.imgBase, alt: "" },
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "title",
                                attrs: { title: item.title },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                            _c("span", [
                              _vm._v("(" + _vm._s(item.numPeo) + "人)"),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "margin_bottom" },
                        [
                          _c(
                            "a-checkbox",
                            {
                              staticClass: "peo_per",
                              attrs: { value: item.id },
                              on: { change: _vm.onChange },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "peo_box" },
                                [
                                  _c("CbAvater", {
                                    attrs: {
                                      "on-job-id": item.onJobId,
                                      "avater-name": item.staffName,
                                      size: "30",
                                    },
                                  }),
                                  _c("span", { staticClass: "jobNumber" }, [
                                    _vm._v(_vm._s(item.phoneNum)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ])
              }),
              0
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "perNoData_div" },
          [_c("CbNoData", { staticClass: "perNoData" })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }